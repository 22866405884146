import { render, staticRenderFns } from "./WinLoseReportByMember.vue?vue&type=template&id=5a2f2a8b&scoped=true&"
import script from "./WinLoseReportByMember.vue?vue&type=script&lang=js&"
export * from "./WinLoseReportByMember.vue?vue&type=script&lang=js&"
import style0 from "./WinLoseReportByMember.vue?vue&type=style&index=0&id=5a2f2a8b&lang=scss&scoped=true&"
import style1 from "./WinLoseReportByMember.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2f2a8b",
  null
  
)

export default component.exports